'use strict'

angular
  .module 'mundoComponents'
  .config ($stateProvider) ->
    $stateProvider
      .state 'components',
        abstract: true,
        url: '/components'
        templateUrl: 'mundo-components/views/mundo-components.tpl.html'
        anonymous: true
